<template>
  <div class="container">
    <h2 class="step-header">Enter your contact information</h2>
    <p class="text">Your email is essential for setting up your account, receiving booking updates, and important notifications from FlashyShoots. Please ensure accuracy for a seamless experience.</p>
    <div class="grid">
      <div class="grid-item">
        <p class="label">First Name</p>
        <BaseInput v-model="userData.firstName" type="text" class="input" @update:value="handleUserDataChange('firstName', $event)" />
      </div>
      <div class="grid-item">
        <p class="label">Last Name</p>
        <BaseInput v-model="userData.lastName" type="text" class="input" @update:value="handleUserDataChange('lastName', $event)" />
      </div>
      <div class="grid-item">
        <p class="label">Email Address</p>
        <BaseInput v-model="$v.userData.email.$model" :validators="[handleEmailValidation]" mandatory :error="$v.userData.email.$error" :label="'Email Address'" />
      </div>
      <div class="grid-item">
        <p class="label">Phone Number</p>
        <BasePhoneNumber v-model="userData.phoneNumber" @update:value="handleUserDataChange('phoneNumber', $event)" class="number" />
      </div>
    </div>
    <!-- Checkbox for phone number approval -->
    <BaseCheckbox
      v-model="userData.phoneNumberApproval"
      @update:value="handleUserDataChange('phoneNumberApproval', $event)"
      label="By selecting this checkbox, you approve our use of this phone number according to our privacy policy."
      class="checkbox"
    />
  </div>
</template>

<script>
import BasePhoneNumber from '@/components/base/BasePhoneNumber.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseInput from '@base/BaseInput.vue';
import { required } from 'vuelidate/lib/validators';
const isFalse = value => value === false;
const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const emailValidator = value => {
  const patternTest = emailPattern.test(value);
  return patternTest;
};
import helpers from '@utils/mixins/registration';
import { emailValidationFlashyShoots } from '@utils/mixins/email-validation-mixin';

export default {
  name: 'Step4',
  mixins: [helpers, emailValidationFlashyShoots],
  components: {
    BasePhoneNumber,
    BaseCheckbox, // Registering the BaseCheckbox component
    BaseInput
  },
  data() {
    return {
      userData: {
        firstName: '',
        lastName: '',
        email: '',
        emailExists: null,
        phoneNumber: '',
        phoneNumberApproval: false // For tracking the checkbox value
      }
    };
  },
  validations: {
    userData: {
      email: { required, emailValidator },
      emailExists: { required, isFalse }
    }
  },
  methods: {
    handleUserDataChange(field, value) {
      this.userData[field] = value;
      this.$emit('user-data-changed', this.userData);
    }
  }
};
</script>
<style scoped lang="scss">
/* Add your custom styles for Step 2 here */

.container {
  display: flex;
  flex-direction: column;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px; /* Sets the horizontal gap between columns */
}

.grid-item {
  display: flex;
  flex-direction: column;
  background-origin: gray;
  padding: 0;
  margin: 0;
}

.label {
  margin: 0;
  padding: 0;
  align-self: flex-start;
  margin-bottom: 8px;
}

.step-header {
  font-size: 18px;
  font-weight: normal;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
  align-self: flex-start;
  background-origin: red;
}

.btn {
  background: #fecb30;
  margin-top: 18px;
  align-self: flex-start;
  width: 320px;
}

.text {
  font-size: 16px;
  font-weight: 200;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 16px;
}

.number {
  background-color: transparent !important;
}

.checkbox {
  width: 450px;
  margin-top: 16px;
}

@include responsive($max: mobileL) {
  .grid {
    grid-template-columns: 1fr;
  }

  .checkbox {
    width: 100%;
  }

  .text {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px;
  }
}
</style>
