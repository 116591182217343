<template>
  <q-dialog content-class="packageExampleDialog__dialog" :value="show" @input="handleToggleItemsDetails" :maximized="isMobile">
    <div class="packageExampleDialog__body">
      <PackageExample :pkg="item" @close="handleToggleItemsDetails" />
    </div>
  </q-dialog>
</template>

<script>
//Components
import PackageExample from '@/components/PackageExample.vue';

export default {
  name: 'PackageExampleDialog',
  components: {
    PackageExample
  },
  props: {
    /**
     * Package to be displayed
     */
    item: { default: () => ({}), type: Object },
    /**
     * Use this prop to show/hide the dialog
     */
    show: { default: false, type: Boolean }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 455; // Adjust the breakpoint as needed
    }
  },
  methods: {
    /**
     * Emitting toggle evento to close the dialog
     */
    handleToggleItemsDetails() {
      this.$emit('toggle');
    }
  }
};
</script>

<style lang="scss">
.packageExampleDialog {
  &__body {
    background-color: transparent;
    box-shadow: unset !important;
    height: unset;
    max-width: unset !important;
    width: unset;
  }
}

@include responsive($max: md) {
  .packageExampleDialog {
    &__dialog {
      .q-dialog__inner {
        padding: 0;
      }
    }

    &__body {
      .baseCard {
        border-radius: 0;
        height: 100vh !important;
        padding: 16px !important;
      }
    }
  }
}

@include responsive($min: md, $max: tablet) {
  .packageExampleDialog {
    &__dialog {
      .q-dialog__inner {
        padding: 0;
      }
    }
  }
}
</style>
