<template>
  <div class="container">
    <h2 class="step-header">Verify your booking details</h2>
    <div class="grid">
      <div style="display: flex; flex-direction: column">
        <h3 class="section-header">Customer details</h3>
        <div class="detail-container">
          <q-icon name="person" size="26px" />
          <p class="name">{{ isUserLoggedIn ? authUser.name : capitalizeFirstLetter(user.firstName) + ' ' + capitalizeFirstLetter(user.lastName) }}</p>
        </div>
        <div class="detail-container">
          <q-icon name="mail" size="26px" />
          <p>{{ isUserLoggedIn ? authUser.email : user.email }}</p>
        </div>
        <div class="detail-container">
          <q-icon name="call" size="26px" />
          <p>{{ isUserLoggedIn ? authUser.phone : user.phoneNumber }}</p>
        </div>
        <h3 class="section-header">Shoot details</h3>
        <div class="detail-container">
          <q-icon name="location_on" size="26px" />
          <p>{{ location.formatted_address }}</p>
        </div>
        <div class="detail-container">
          <q-icon name="schedule" size="26px" />
          <p>{{ formatted_date }}</p>
        </div>
      </div>
      <div class="detail-container" style="display: flex; flex-direction: column">
        <h3 class="section-header">Coupon code</h3>
        <p style="padding: 0; margin: 0; margin-bottom: 6px">Have a coupon? Type it here</p>
        <BaseInput type="text" class="input" label="Coupon Code" placeholder="WINTER10" v-model="coupon_code" @update:value="handleCouponCode" />
      </div>
      <div style="display: flex; flex-direction: column">
        <h3 class="section-header">Package details</h3>
        <PackageCard :pkg="pkg" hide-actions class="pkg" />
      </div>
    </div>
  </div>
</template>

<script>
import PackageCard from '../../../components/package/PackageCard.vue';
import { mapGetters } from 'vuex';
import BaseInput from '@base/BaseInput.vue';

export default {
  name: 'Step6',
  props: {
    pkg: Object,
    user: Object,
    location: Object,
    time: Object
  },
  data() {
    return {
      formatted_date: '',
      coupon_code: ''
    };
  },
  components: {
    PackageCard,
    BaseInput
  },
  created() {
    const formatted = this.formatISOToLocalTime(this.time.from);
    this.formatted_date = formatted;
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'user/isUserLoggedIn',
      authUser: 'user/getUser'
    })
  },
  methods: {
    formatISOToLocalTime(isoDateString) {
      // Extract the date and time parts from the ISO string
      const [datePart, timePart] = isoDateString.split('T');

      // Extract year, month, and day from the date part
      const [year, month, day] = datePart.split('-');

      // Extract hours and minutes from the time part
      const [hours, minutes] = timePart.substring(0, 5).split(':');

      // Map for day of the week and month names
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      // Create a new date object to get the day of the week
      const date = new Date(year, month - 1, day);
      const dayOfWeek = days[date.getDay()];

      // Format the output
      const formattedDate = `${hours}:${minutes}, ${dayOfWeek} ${day} ${months[month - 1]} ${year}`;
      return formattedDate;
    },
    capitalizeFirstLetter(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    handleCouponCode(code) {
      this.coupon_code = code;
      this.$emit('coupon-code', code);
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  column-gap: 24px;
}

.detail-container {
  display: flex;
}
p {
  margin-left: 12px;
  display: flex;
  align-items: center;
}

.step-header {
  font-size: 18px;
  font-weight: normal;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 6px;
  align-self: flex-start;
}
.section-header {
  font-size: 16px;
  font-weight: bold;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 6px;
  align-self: flex-start;
}
.pkg {
  background-color: rgba(255, 255, 255, 0.5);
  border: 0.5px solid rgba(0, 0, 0, 0.15);
}
@include responsive($max: mobileL) {
  .grid {
    display: flex;
    flex-direction: column-reverse;
    column-gap: auto;
    padding-bottom: 24px;
  }
  .pkg {
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
