<template>
  <div class="container">
    <h2 class="step-header">Verify your phone number</h2>
    <div class="grid">
      <div>
        <p class="text">
          We've sent a 6-digit verification code to <strong>{{ phoneNumber }}</strong> . Please enter the code below to confirm that your phone number is valid.
        </p>
        <div style="display: flex; flex-direction: row">
          <v-otp-input ref="otpInput" input-classes="otp" :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChange" @on-complete="handleOnComplete" separator="" />
        </div>
        <div class="resend-container">
          <label class="resend-text">Didn't receive the code?</label>
          <BaseButton :disabled="isResendDisabled" :label="isResendDisabled ? `${countdown} seconds` : 'Resend code'" @click.native="handleResendClick" type="default" />
        </div>
        <p class="support-text">If you're having trouble receiving the code, feel free to reach out to us for assistance.</p>
      </div>

      <div class="image-container">
        <v-lazy-image
          :src="'https://flashy-packages-pictures20220201104541386600000001.s3.eu-central-1.amazonaws.com/assets/camera.jpg'"
          :src-placeholder="'https://flashy-packages-pictures20220201104541386600000001.s3.eu-central-1.amazonaws.com/assets/camera-placeholder.jpg'"
          class="img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/base/BaseButton.vue';
import VLazyImage from 'v-lazy-image/v2';

import helpers from '@utils/mixins/registration';
import { required } from 'vuelidate/lib/validators';
import { UserApi } from '@api/index';
const isString = value => !!value && typeof value === 'string';

const otpValidator = value => {
  return value.length === 6;
};

export default {
  name: 'Step5',
  mixins: [helpers],
  props: {
    phoneNumber: String
  },
  components: {
    BaseButton,
    VLazyImage
  },
  data() {
    return {
      otp: '',
      isResendDisabled: false,
      timer: null,
      countdown: 60, // Duration in seconds
      imageLoaded: false
    };
  },
  validations: {
    otp: { required, isString, otpValidator }
  },
  mounted() {
    this.checkValidation();
  },
  methods: {
    handleOnComplete(value) {
      this.otp = value.toString();
      this.otp = value;
    },
    handleOnChange(value) {
      this.otp = value.toString();
      this.$emit('otp-changed', this.otp);
      this.checkValidation();
    },
    handleClearInput() {
      this.otp = '';
      this.$refs.otpInput.clearInput();
    },
    startTimer() {
      this.isResendDisabled = true;
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          this.resetTimer();
        }
      }, 1000);
    },

    resetTimer() {
      clearInterval(this.timer);
      this.isResendDisabled = false;
      this.countdown = 60; // Reset to initial duration
    },

    async handleResendClick() {
      this.startTimer();
      const response = await UserApi.sendOtp(this.phoneNumber);
      if (response.statusCode === 200) {
        this.$store.dispatch('notification/addSuccessNotification', 'Verification code has been sent');
      } else {
        this.$store.dispatch('notification/addFailureNotification', 'Failed to send OTP');
        return;
      }
      // Add your logic to handle the resend code action
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
};
</script>
<style lang="scss" scoped>
/* Add your custom styles for Step 2 here */

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.step-header {
  font-size: 18px;
  font-weight: normal;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 6px;
  align-self: flex-start;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.resend-container {
  display: flex;
  flex: row;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: left;
}
.resend-text {
  margin-right: 12px;
}

.text {
  margin-top: 16px;
  width: 95%;
  text-align: left;
}

.support-text {
  width: 95%;
  text-align: left;
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
.image-container {
  position: relative;
  width: 100%;
  height: 315px;
  .v-lazy-image,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover; /* Adjust as needed to fill the container */
  }
}

@include responsive($max: mobileL) {
  .grid {
    display: flex;
    flex-direction: column-reverse;
  }
  img,
  .text,
  .support-text {
    width: 100%;
  }
  .resend-text {
    margin-right: auto;
  }
}
</style>
