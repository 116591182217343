<template>
  <div :class="[{ container: !isMobile }, { 'mobile-container': isMobile }]">
    <!-- Header for Step 2 -->
    <h2 class="step-header">Select your desired package</h2>
    <PkgCarousel v-if="packages.length > 0 && isMobile" :items="packages" :width="300" :height="500" @package-selected="handlePackageSelection" />

    <!-- Container for Package Cards with selection logic -->
    <div class="packages-grid" v-if="packages.length > 0 && !isMobile">
      <PackageCard
        v-for="pkg in packages"
        :key="pkg.id"
        @click.native="handlePackageSelectionDesktop(pkg)"
        :pkg="pkg"
        hide-actions
        class="package"
        :class="{ 'package-selected': selectedPackage && pkg.id === selectedPackage.id }"
      />
    </div>
  </div>
</template>

<script>
import { PackagesApi } from '@api/index';
import PkgCarousel from '@/components/package/PkgCarousel.vue';
import PackageCard from '@/components/package/PackageCard.vue';

export default {
  name: 'Step2',
  props: {
    user: Object, // You may need to pass the user information if required
    serviceId: Number
  },
  components: {
    PkgCarousel,
    PackageCard
  },
  data() {
    return {
      packages: [], // Array to store the list of packages
      selectedPackage: null // Package that is currently selected
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 455; // Adjust the breakpoint as needed
    }
  },
  async created() {
    // Retrieve packages based on the selected service from Step 1
    this.retrievePackages();
  },
  methods: {
    /**
     * Method used to retrieve packages based on the selected service
     */
    async retrievePackages() {
      try {
        // Assuming PackagesApi.search returns a promise that resolves with the packages response
        const response = await PackagesApi.search({
          payload: {
            filters: {
              services: [this.serviceId]
            }
          }
        });

        if (response.statusCode === 200) {
          // Filter the packages to include only those with package_type 'b2c'
          this.packages = response.data.packages.filter(pkg => pkg.package_type === 'b2c');
        } else {
          // Handle the error case
          console.error('Failed to retrieve packages:', response.message);
        }
      } catch (error) {
        // Handle any exceptions or network errors
        console.error('Error while fetching packages:', error);
      }
    },

    /**
     * Method used to handle package selection
     */
    handlePackageSelection(index) {
      // Deselect the previously selected package
      // Select the clicked package
      this.$emit('package-selected', [this.packages[index]]);
    },
    handlePackageSelectionDesktop(pkg) {
      // Deselect the previously selected package
      // Select the clicked package
      this.selectedPackage = pkg;
      this.$emit('package-selected', [this.selectedPackage]);
    }
  }
};
</script>

<style scoped lang="scss">
/* Add your custom styles for Step 2 here */

.container-mobile {
  height: 75vh;
  overflow-y: scroll;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.step-header {
  font-size: 18px;
  font-weight: normal;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 6px;
}

.packages-grid {
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  max-height: 375px;
}

.package {
  background-color: rgba(255, 255, 255, 0.5) !important;
  margin-bottom: 12px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  cursor: pointer !important;
  transition: all 0.5s;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
}

.package-selected {
  background-color: #feca2fca !important;
}

.package:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

@include responsive($max: mobileL) {
}
</style>
