<template>
  <div class="container" v-if="showPreview">
    <BaseIcon class="close" icon="arrow-left" stroke="#151515" @click.native="handleBack" />
    <p class="pkg-name">{{ pkg.name }}</p>
    <p class="pkg-category">Category: {{ getServiceName({ id: pkg.service_id }) }}</p>
    <img :src="selectedImage.src" class="preview-img" />
  </div>
  <div class="container" v-else>
    <BaseIcon class="close" icon="close" stroke="#151515" @click.native="$emit('close', false)" />
    <p class="pkg-name">{{ pkg.name }}</p>
    <p class="pkg-category">Category: {{ getServiceName({ id: pkg.service_id }) }}</p>
    <p class="pkg-desc">{{ pkg.description }}</p>
    <div class="row justify-center q-my-md" v-if="!imagesLoaded">
      <q-spinner-dots color="primary" size="150px" />
    </div>
    <div v-else class="imgs-grid">
      <div class="grid-item" v-for="image in imagesData" v-bind:key="image.id" @click="selectImage(image)">
        <img :src="image.src" class="grid-img" />
        <!-- <div class="overlay"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from '@base/BaseIcon.vue';
/**
 * Component used to show the available packages info
 *
 * @displayName PackageExample
 */
export default {
  name: 'PackageExample',

  props: {
    /**
     * The package we want to show the info
     */
    pkg: {
      default: () => ({}),
      type: Object
    }
  },
  data() {
    return {
      selectedImage: {},
      imagesData: [], // Store images
      imagesLoaded: false,
      showPreview: false
    };
  },
  components: {
    BaseIcon
  },
  computed: {
    isMobile() {
      return window.innerWidth < 455; // Adjust the breakpoint as needed
    }
  },
  methods: {
    // Method to select an image
    selectImage(image) {
      this.selectedImage = image;
      this.showPreview = true;
    },
    handleBack() {
      this.selectedImage = {};
      this.showPreview = false;
    },
    async fetchImages() {
      const imagesData = [];

      for (let i = 1; i <= this.pkg.num_pictures; i++) {
        const src = this.getPackageImage({ idPackage: this.pkg.id, idAsset: i });

        // Load each image and calculate aspect ratio
        await new Promise(resolve => {
          const img = new Image();
          img.onload = () => {
            // Calculate aspect ratio (width / height)
            const aspectRatio = img.width / img.height;
            imagesData.push({ id: i, src, aspectRatio });
            resolve();
          };
          img.src = src;
        });
      }

      // Sort images by aspect ratio, from highest to lowest
      this.imagesData = imagesData.sort((a, b) => a.aspectRatio - b.aspectRatio);

      this.imagesLoaded = true;
    }
  },
  async created() {
    await this.fetchImages();
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 800px !important;
  height: 650px !important;
  border-radius: 10px;
  padding: 24px;
  background-color: #fefefa;
  position: relative;
}

.pkg-name {
  @include antonio-font($size: 18px, $uppercase: true, $color: var(--main-text-color));
  margin-bottom: 6px;
}
.pkg-category {
  font-weight: 300;
  font-size: 14px;
}
.pkg-desc {
  font-weight: 400;
  font-size: 15px;
}

.imgs-grid {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px; /* Adjust the gap between images */
  overflow: scroll;
}

.grid-item {
  width: 240px;
  height: auto;
  position: relative;
  cursor: pointer;
  border-radius: 10px !important;
}

.grid-img {
  object-fit: cover;
  border-radius: 10px !important;
  width: 100%;
  height: 100%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  color: white; /* Text color for city name */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; /* Always visible */
  transition: opacity 0.3s ease; /* Add a smooth transition effect */
  border-radius: 10px !important;
}

.grid-item:hover > .overlay {
  background: #fecb2f; /* Change overlay background color */
  opacity: 0.6;
}

.close {
  position: absolute !important;
  top: 24px !important;
  right: 24px !important;
  cursor: pointer;
}
.preview-img {
  object-fit: contain !important;
  height: 535px !important;
  width: auto !important;
  border-radius: 10px !important;
}
@include responsive($max: mobileL) {
  .container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    min-height: 100%;
    height: auto !important;
    border-radius: 0;
    padding: 24px;
    background-color: #fefefa;
  }
  .imgs-grid {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px; /* Adjust the gap between images */
    overflow: scroll;
  }

  .grid-item {
    width: 100%;
    height: auto;
    position: relative;
    cursor: pointer;
    border-radius: 10px !important;
  }

  .grid-img {
    object-fit: cover;
    border-radius: 10px !important;
    width: 100%;
  }
}
</style>
