<template>
  <div class="container">
    <!-- Header -->
    <h2 class="header">Select a city</h2>
    <!-- Two-column grid of service categories -->
    <div class="cities-grid">
      <div
        v-for="city in cities"
        :key="city.img"
        @click="handleCitySelection(city.location)"
        class="grid-item"
        :class="{ 'city-selected': selectedCity && selectedCity.city === city.name }"
        :style="{ backgroundImage: `url('${city.img}')` }"
      >
        <p class="city-name">{{ city.name }}</p>
        <div class="overlay"></div>
        <!-- Add an overlay div -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step0',
  data() {
    return {
      selectedCity: null,
      cities: [
        {
          name: 'Dubai',
          img: 'https://flashy-packages-pictures20220201104541386600000001.s3.eu-central-1.amazonaws.com/cities/dubai.webp',
          location: {
            city: 'Dubai',
            country: 'AE',
            formatted_address: 'Dubai - United Arab Emirates',
            state: 'United Arab Emirates',
            utc_offset_minutes: 240
          }
        },
        {
          name: 'Abu Dhabi',
          img: 'https://flashy-packages-pictures20220201104541386600000001.s3.eu-central-1.amazonaws.com/cities/abudhabi.webp',
          location: {
            city: 'Abu Dhabi',
            country: 'AE',
            formatted_address: 'Abu Dhabi - United Arab Emirates',
            state: 'United Arab Emirates',
            utc_offset_minutes: 240
          }
        }
      ]
    };
  },

  methods: {
    /**
     * Method used to set the selected category
     */
    handleCitySelection(selectedCity) {
      this.selectedCity = selectedCity;
      // Emit an event with the selected service data
      this.$emit('city-selected', selectedCity);
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  font-size: 18px;
  font-weight: normal;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 6px;
}

.cities-grid {
  display: flex;
  flex-direction: row;
  gap: 24px; /* Adjust the gap between items as needed */
  justify-content: center;
  align-items: center;
}

.grid-item {
  height: 300px !important;
  width: 100% !important;
  background-position: center;
  background-size: cover;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  cursor: pointer;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  color: white; /* Text color for city name */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; /* Always visible */
  transition: opacity 0.3s ease; /* Add a smooth transition effect */
  border-radius: 15px;
}

.city-name {
  margin-top: 8px;
  font-size: 20px;
  color: white;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: opacity 0.3s ease; /* Add a smooth transition effect */
}

.city-selected .overlay {
  background: #fecb2f; /* Change overlay background color */
  opacity: 0.6;
}
.city-selected .city-name {
  color: black;
}

@include responsive($max: mobileL) {
  .cities-grid {
    flex-direction: column;
  }
  .city-img {
    width: 100%;
    height: 100px;
  }

  .grid-item {
    height: 240px !important;
  }

  /* .header {
    color: white;
  } */
}
</style>
