<template>
  <q-card class="container">
    <div class="top" :style="{ backgroundImage: `url(${img})` }"></div>
    <div class="bot">
      <p class="pkg-name">{{ pkg.name }}</p>
      <p class="pkg-text" v-snip="{ lines: 6 }">
        {{ pkg.description }}
      </p>
      <div class="rate-container">
        <p class="rate">{{ pkg.duration }}h / {{ pkg.picture_number }} images</p>
        <p class="price">{{ pkg.price }} AED</p>
      </div>
    </div>
  </q-card>
</template>

<script>
export default {
  name: 'PackageCardMobile',
  props: {
    img: { default: null, type: String },
    pkg: { default: {} }
  },
  data() {
    return {
      method: 'js',
      maxLines: 5
    };
  }
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  height: 600px;
  width: 300px;
  display: grid;
  grid-template-rows: 37% 68%; /* Adjust the proportions as needed */
  border-radius: 15px;
}

.top {
  background-position: center;
  background-size: cover;
  position: relative;
}

.bot {
  position: relative;
  background: #fafafa; /* Change overlay background color */
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 15px;
}

.pkg-name {
  font-size: 15px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 6px;
  text-transform: uppercase;
}

.pkg-text {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.rate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 14px;
  border-radius: 15px;
  margin-top: 40px;
}

.rate,
.price {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.price {
  font-weight: bold;
}
</style>
