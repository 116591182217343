<template>
  <div class="container">
    <!-- Header -->
    <h2 class="header">Select a category</h2>
    <!-- Two-column grid of service categories -->
    <div class="services-grid">
      <div v-for="service in services" :key="service.id">
        <div
          class="service"
          :style="{ backgroundImage: `url(${getServiceImage(service.id)})` }"
          @click="handleServiceSelection(service)"
          :class="{ 'service-selected': service.id === selectedService.id }"
        >
          <div class="service-overlay" />
          <p class="service-name">{{ service.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Step1',
  data() {
    return {
      selectedService: {} // Stores the selected service
    };
  },
  computed: {
    ...mapGetters({
      services: 'services/getServices'
    })
  },
  methods: {
    /**
     * Method used to set the selected category
     */
    handleServiceSelection(selectedService) {
      this.selectedService = selectedService;
      // Emit an event with the selected service data
      this.$emit('service-selected', selectedService);
    }
    /**
     * Method used to retrieve services
     */
  }
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  overflow-y: scroll;
}
.header {
  font-size: 18px;
  font-weight: normal;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 6px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns with equal width */
  gap: 12px;
  margin: 0;
  padding: 0;
  height: 325px;
  overflow-y: scroll;
}

.service {
  background-color: lightgrey;
  height: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
  position: relative;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.service-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
}

.service-name {
  margin-top: 8px;
  font-size: 16px;
  position: absolute;
  color: white;
}

.service-selected {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.service-selected .service-overlay {
  background-color: #feca2f9d;
}
.service-selected .service-name {
  color: black;
  font-weight: bold;
}

@include responsive($max: mobileL) {
  .services-grid {
    grid-template-columns: 1fr 1fr !important;
    height: auto;
    overflow-y: scroll;
  }

  .service {
    height: 125px;
  }
}
</style>
