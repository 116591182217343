<template>
  <BaseCard :data-size="size" class="packageCard">
    <template #header>
      <section class="packageCard__header">
        <div class="row justify-between" style="padding-right: 25px">
          <div class="packageCard__name text-left">{{ pkg.name }}</div>
          <BaseIcon
            v-if="pkg.is_plus"
            icon="diamond"
            :custom_style="{ height: '25px', width: '20px', position: 'absolute', right: '15px' }"
            :fill="getCssVariable('--diamond-color')"
            :stroke="getCssVariable('--diamond-color')"
          />
        </div>
        <div class="packageCard__category text-left mt-5">{{ $tc('CATEGORY', 1) | firstUppercase }}: {{ getServiceName({ id: pkg.service_id }) }}</div>
      </section>
    </template>
    <template #body>
      <section class="packageCard__body">
        <div class="packageCard__info mb-10">
          <div class="packageCard__shoot-info">{{ pkg.duration }}h / {{ $tc('PICTURE', pkg.picture_number) }}</div>
          <div class="packageCard__price-info">{{ pkg.price }} {{ pkg.currency }}</div>
        </div>
        <div v-if="!hideActions" class="packageCard__actions">
          <div
            v-for="(action, index) of actions"
            :key="index"
            :class="['packageCard__action', { 'packageCard__action--is-selected': isPackageHidden(action.value) }]"
            @click="handleAction(action.value)"
          >
            <BaseIcon :icon="action.icon" :custom_style="{ height: action.size, stroke: isPackageHidden(action.value) ? '#fff' : '#000', width: action.size }" :stroke="action.color" />
          </div>
        </div>
      </section>
    </template>
    <template #footer>
      <section class="packageCard__footer mt-15">
        <div class="packageCard__example" @click="showExample = true">
          <img src="@/assets/svg/image.svg" />
          <p>{{ $t('SOME_EXAMPLE') }}</p>
        </div>
        <BaseImage class="packageCard__image" :src="getServiceImage(pkg.service_id)" />
      </section>
      <PackageExampleDialog :item="pkg" :show="showExample" @toggle="showExample = false" />
    </template>
  </BaseCard>
</template>

<script>
// TODO: Merge this component and Package.vue component. Add 'show examples'.

// Store
import { mapActions } from 'vuex';

// Api
import { PackagesApi } from '@api/index';

// Base Components
import BaseCard from '@base/BaseCard.vue';
import BaseImage from '@base/BaseImage.vue';
import BaseIcon from '@base/BaseIcon.vue';

// Components
import PackageExampleDialog from './PackageExampleDialog.vue';

const HIDE = 'HIDE';
const EDIT = 'EDIT';
const DELETE = 'DELETE';

export default {
  name: 'PackageCard',
  components: {
    BaseCard,
    BaseImage,
    BaseIcon,
    PackageExampleDialog
  },
  props: {
    /**
     * Set to true to remove actions
     */
    hideActions: { default: false, type: Boolean },
    /**
     * If the package is displayed on the carousel, set to true only if it's the current slide
     */
    isActive: { default: true, type: Boolean },
    /**
     * The package object with all the informations
     * Using this name because using 'package' will cause error because its a reserved key
     */
    pkg: {
      default: () => ({}),
      type: Object
    },
    /**
     * Set the size of the card
     */
    size: { default: 'medium', type: String }
  },
  data() {
    return {
      HIDE: HIDE,
      actions: [
        {
          color: this.pkg.hide && '#fff',
          icon: 'eye-off',
          size: '18px',
          value: HIDE
        },
        {
          icon: 'edit',
          size: '14px',
          value: EDIT
        },
        {
          color: this.getCssVariable('--packages-trash-action'),
          icon: 'trash',
          size: '17px',
          value: DELETE
        }
      ],
      showExample: false
    };
  },
  methods: {
    ...mapActions({
      setSelectedPackage: 'pkg/saveOpenedPackage'
    }),

    /**
     * Deleting package
     */
    async delete() {
      const deletePackage = await PackagesApi.delete(this.pkg.id);

      if (deletePackage.statusCode === 200) {
        this.$emit('package:delete', this.pkg);
        this.$store.dispatch('notification/addSuccessNotification', this.$t('NOTIFICATIONS.PACKAGES.DELETE', { name: this.pkg.name }));
      }
    },
    /**
     * Method used to dispatch which action has been clicked
     */
    handleAction(action) {
      /**
       * Disable all actions if the package is in the background
       */
      if (!this.isActive) return;

      const actions = {
        [DELETE]: () => this.delete(),
        [EDIT]: () => this.$emit('package:update'),
        [HIDE]: () => this.updateHide()
      };

      actions[action]();
    },
    /**
     * Returns true if package is hidden (will not be displayed in book a new shoot wizard)
     */
    isPackageHidden(value) {
      return this.pkg.status === 'hidden' && value === HIDE;
    },
    /**
     * Method used to update package hide prop
     */
    async updateHide() {
      const hide = this.pkg.status !== 'hidden';
      const updateHide = await PackagesApi.update({ id: this.pkg.id, payload: { ...this.pkg, hide } });

      if (updateHide.statusCode === 200) {
        this.$set(this.pkg, 'status', updateHide.data.pack.status);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.packageCard {
  $card-padding: 15px;

  display: flex;
  flex-direction: column;

  &[data-size='medium'] {
    height: auto;
    width: 240px;
  }

  &[data-size='small'] {
    height: auto;
    width: 210px;
  }

  &__action {
    align-items: center;
    background-color: var(--packages-info-bg);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 35px;
    justify-content: center;
    width: 64px;

    &--is-selected {
      background-color: var(--packages-action-selected);
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    padding: 0 $card-padding;
  }

  &__category {
    @include inter-font;
  }

  &__example {
    align-items: center;
    background: white;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: space-between;
    left: 50%;
    padding: 9px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 137px;
    > img {
      height: 15px;

      @include responsive($max: mobileL) {
        width: fit-content;
      }
    }
    > p {
      font-weight: bold;
      @include inter-font($size: 10px, $color: var(--main-text-color));
      margin: 0;
    }
  }

  &__footer {
    height: 94px;
    position: relative;
  }

  &__image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__info {
    background-color: var(--packages-info-bg);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 62px;
    width: 100%;

    @include responsive($max: mobileL) {
      padding: 0 1rem;
    }
  }

  &__price-info {
    @include inter-font($bolded: true);
  }

  &__shoot-info {
    @include inter-font;
  }

  &__header {
    padding: $card-padding;
  }

  &__name {
    @include antonio-font;
  }
}
</style>
