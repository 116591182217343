<template>
  <img :src="src" @error="handleError" />
</template>

<script>
/**
 * Component used to show images with fallback
 */
export default {
  name: 'BaseImage',
  props: {
    /**
     * Image source
     */
    src: { deault: '', String }
  },
  methods: {
    /**
     * Method used to handle image load error and show fallback one instead
     */
    handleError() {
      this.$el.src = this.requireMedia('/rights_text.png');
    }
  }
};
</script>
