<template>
  <div class="wrapper" ref="wrapper" :style="{ backgroundImage: `url(${require(`@/assets/background.jpg`)})`, height: isUserLoggedIn ? '100%' : '100vh' }">
    <div class="overlay"></div>
    <div class="header" ref="header">
      <img src="https://assets-global.website-files.com/62507fe31aec3510292671df/6570080bb5160a80c87f4308_new-shoots-logo.png" alt="logo" class="logo" ref="logo" />
      <a href="https://app.getflashy.com" target="_blank" class="login">Login</a>
    </div>
    <div class="stepper-wrapper">
      <h1 class="slogon">BOOK A PHOTOSHOOT IN ONLY <span>60 SECONDS</span></h1>
      <h1 class="contact">ANYHING ELSE ? FEEL FREE TO <a href="https://www.getflashy.com/contact" target="_blank">CONTACT US</a></h1>
      <q-stepper v-model="currentStep" color="primary" animated alternative-labels contracted class="stepper">
        <q-step :name="1" title="Select City" :done="currentStep > 1">
          <Step0 :stepData="stepData[0]" @city-selected="handleCitySelection" />
        </q-step>
        <q-step :name="2" title="Select Product Category" :done="currentStep > 2">
          <Step1 :stepData="stepData[1]" @service-selected="handleServiceSelection" />
        </q-step>

        <q-step :name="3" title="Select Package" :done="currentStep > 3">
          <Step2 :serviceId="serviceId" :stepData="stepData[1]" @package-selected="handlePackagesSelection" />
        </q-step>

        <q-step :name="4" title="Select Date and Time" :done="currentStep > 4">
          <Step3 :package="stepData[2].packages[0]" @datetime-selected="handleDatetimeSelection" />
        </q-step>

        <q-step v-if="!isUserLoggedIn" :name="5" title="User Information" :done="currentStep > 5">
          <Step4 @user-data-changed="handleUserDataChange" />
        </q-step>

        <q-step v-if="!isUserLoggedIn" :name="6" title="Phone Verification" :done="currentStep > 6">
          <Step5 @otp-changed="handleOtpChange" :phoneNumber="stepData[4].phoneNumber" />
        </q-step>

        <q-step :name="isUserLoggedIn ? 5 : 7" title="Booking Summary" :done="isUserLoggedIn ? currentStep > 7 : currentStep > 5">
          <Step6 :pkg="stepData[2].packages[0]" :user="isUserLoggedIn ? null : stepData[4]" :location="stepData[0].location" :time="stepData[3].time" @coupon-code="handleCouponCode" />
        </q-step>

        <template v-slot:navigation>
          <q-stepper-navigation class="navigation">
            <q-btn v-if="currentStep > 0" flat color="black" @click="handlePrevStep" label="Back" class="q-ml-sm mr-6" :disabled="currentStep === 1">
              <q-tooltip class="bg-accent" v-if="currentStep === 1">You can't go back</q-tooltip>
            </q-btn>
            <q-btn
              @click="(!isUserLoggedIn && currentStep === 7) || (isUserLoggedIn && currentStep === 5) ? completeRegistration() : handleNextStep()"
              color="primary"
              style="color: black !important"
              :label="(!isUserLoggedIn && currentStep === 7) || (isUserLoggedIn && currentStep === 5) ? 'Pay now' : 'Continue'"
              :disabled="(!isUserLoggedIn && currentStep === 7) || (isUserLoggedIn && currentStep === 5) ? false : nextDisabled"
              :loading="nextLoading"
              :tooltip="'Disabled'"
            />
            <a href="https://www.getflashy.com/contact">CONTACT <span>US</span></a>
          </q-stepper-navigation>
        </template>
      </q-stepper>
    </div>
    <stripe-checkout ref="checkoutRef" :pk="stripePublic" :session-id="stripe.sessionId" @loading="v => (loading = v)" />
  </div>
</template>

<script>
// Store
import { mapGetters } from 'vuex';
import { UserApi, OrderApi, SettingsApi, ServiceApi } from '@api/index';
import { getCookie } from '@utils/cookies';

import { required } from 'vuelidate/lib/validators';
const isString = value => !!value && typeof value === 'string';
const isNumber = value => typeof value === 'number';
const isDate = value => !!value && !isNaN(Date.parse(value));
const isBoolean = value => typeof value === 'boolean';
const isTrue = value => value === true;
const isFalse = value => value === false;

const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneNumberPattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

const emailValidator = value => {
  const patternTest = emailPattern.test(value);
  return patternTest;
};

const phoneNumberValidator = value => {
  const patternTest = phoneNumberPattern.test(value.replace(/\s/g, ''));
  return patternTest;
};

// Components
import Step0 from './person/Step0.vue';
import Step1 from './person/Step1.vue';
import Step2 from './person/Step2.vue';
import Step3 from './person/Step3.vue';
import Step4 from './person/Step4.vue';
import Step5 from './person/Step5.vue';
import Step6 from './person/Step6.vue';

import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
  name: 'FlashyShoots',
  metaInfo() {
    return {
      title: 'Person Registration',
      titleTemplate: '%s | – Person Registration',
      htmlAttrs: { lang: 'en-US' },
      meta: [{ name: 'description', content: 'Register for our services.' }]
    };
  },
  components: {
    Step0,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    StripeCheckout
  },
  data() {
    return {
      nextDisabled: true,
      nextLoading: false,
      loading: false,
      utm: {
        utm_source: null,
        utm_medium: null,
        utm_campaign: null
      },
      stripe: {
        sessionId: null,
        couponCode: ''
      },
      currentStep: 1, // Track the current step
      stepData: [
        // State for each step
        {
          location: {
            city: null,
            country: null,
            formatted_address: null,
            state: null,
            utc_offset_minutes: null
          }
        },
        {
          service: {
            creation_date: null,
            id: null,
            last_update: null,
            name: null,
            num_pictures: null,
            purpose_id: null,
            status: null
          }
        },
        {
          packages: [
            {
              creation_date: null,
              currency: '',
              description: '',
              duration: null,
              id: null,
              is_plus: false,
              last_update: null,
              name: '',
              num_pictures: null,
              photographer_revenue: '',
              picture_number: null,
              price: '',
              price_per_additional_photo: '',
              service_id: null,
              status: ''
            }
          ]
        },
        {
          time: {
            from: null,
            duration: null
          }
        },
        {
          firstName: '',
          lastName: '',
          email: '',
          emailExists: null,
          phoneNumber: '',
          phoneNumberApproval: false
        },
        {
          otp: null
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'user/isUserLoggedIn',
      authUser: 'user/getUser',
      settings: 'settings/getSettings'
    }),

    location() {
      return this.stepData[0].location;
    },
    service() {
      return this.stepData[1].service;
    },
    pkg() {
      return this.stepData[2].packages[0];
    },
    date() {
      return this.stepData[3].time ? this.stepData[3].time.from : null;
    },
    userData() {
      return this.stepData[4];
    },
    otp() {
      return this.stepData[5].otp;
    },
    stripePublic() {
      return this.settings.stripe_public;
    },
    serviceId() {
      return this.stepData[1].service.id;
    }
  },
  watch: {
    currentStep: {
      handler() {
        if ((!this.isUserLoggedIn && this.currentStep === 7) || (this.isUserLoggedIn && this.currentStep === 5)) return;
        this.nextDisabled = true;
      },
      immediate: true,
      deep: true
    },
    location: {
      handler(newLocation) {
        const isValid = this.validateLocation(newLocation);
        if (isValid) this.nextDisabled = false;
        else this.nextDisabled = true;
      },
      immediate: true,
      deep: true
    },
    service: {
      handler(newService) {
        const isValid = this.validateService(newService);
        if (isValid) this.nextDisabled = false;
        else this.nextDisabled = true;
      },
      immediate: true,
      deep: true
    },
    pkg: {
      handler(newPkg) {
        const isValid = this.validatePackage(newPkg);
        if (isValid) this.nextDisabled = false;
        else this.nextDisabled = true;
      },
      immediate: true,
      deep: true
    },
    date: {
      handler(newDate) {
        const isValid = this.validateDate(newDate);
        if (isValid) this.nextDisabled = false;
        else this.nextDisabled = true;
      },
      immediate: true,
      deep: true
    },
    userData: {
      handler(newUserData) {
        const isValid = this.validateUserData(newUserData);
        if (isValid) this.nextDisabled = false;
        else this.nextDisabled = true;
      },
      immediate: true,
      deep: true
    },
    otp: {
      handler(newOtp) {
        const isValid = this.validateOtp(newOtp);
        if (isValid) this.nextDisabled = false;
        else this.nextDisabled = true;
      }
    }
  },
  async created() {
    const [services, settings] = await Promise.all([this.retrieveServices(), SettingsApi.settings()]);
    this.$store.dispatch('services/updateServices', services);
    this.$store.dispatch('settings/updateSettings', settings.data);

    if (!this.isUserLoggedIn) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.size > 0) {
        this.utm = {
          utm_source: urlParams.get('utm_source'),
          utm_medium: urlParams.get('utm_medium'),
          utm_campaign: urlParams.get('utm_campaign')
        };

        return;
      }
      // Get the 'Lead' cookie
      const leadCookieValue = getCookie('Lead');
      if (leadCookieValue) {
        const decodedValue = decodeURIComponent(leadCookieValue);
        const utmData = JSON.parse(decodedValue);
        const utmParameters = utmData.parameters;
        this.utm = { ...utmParameters };
        return;
      }
    }
  },
  mounted() {
    if (!this.isUserLoggedIn) {
      window.addEventListener('beforeunload', this.handleAbandonment);
    }
  },
  beforeDestroy() {
    if (!this.isUserLoggedIn) {
      window.removeEventListener('beforeunload', this.handleAbandonment);
    }
  },
  methods: {
    // Method to handle moving to the next step
    async retrieveServices() {
      try {
        const response = await ServiceApi.search({
          sorting: {
            field: 'id',
            direction: 'desc'
          },
          filters: { names: ['Wedding', 'Family', 'Influencer', 'Portrait', 'Travel', 'Pets', 'Engagement', 'Maternity', 'Newborn', 'Food & Beverage'] }
        });

        if (response.statusCode === 200) {
          return response.data.services;
        } else {
          // Handle the error case
          return null;
        }
      } catch (error) {
        // Handle any exceptions or network errors
        console.error('Error while fetching services:', error);
      }
    },
    async handleNextStep() {
      this.nextLoading = true;
      if (!this.isUserLoggedIn && this.currentStep === 5) {
        const number = this.stepData[4].phoneNumber.replace(/\s+/g, '');
        const response = await UserApi.sendOtp(number);
        if (response.statusCode === 200) {
          this.$store.dispatch('notification/addSuccessNotification', 'Verification code has been sent');
        } else {
          this.$store.dispatch('notification/addFailureNotification', 'Failed to send OTP');
          return;
        }
      } else if (!this.isUserLoggedIn && this.currentStep === 6) {
        const number = this.stepData[4].phoneNumber.replace(/\s+/g, '');
        const otp = this.stepData[5].otp;
        const response = await UserApi.verifyOtp(number, otp);
        if (response.statusCode === 200) {
          this.$store.dispatch('notification/addSuccessNotification', 'Phone number has been verified');
        } else {
          this.$store.dispatch('notification/addFailureNotification', 'Failed to verify phone number');
          return;
        }
      }
      this.nextLoading = false;
      this.currentStep++;

      // Wait for Vue to update the DOM
      this.$nextTick(() => {
        const element = this.$refs.header;
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
          console.error('Failed to find .wrapper element for scrolling.');
        }
      });
    },

    async handlePrevStep() {
      this.currentStep--;

      // Wait for Vue to update the DOM
      this.$nextTick(() => {
        const wrapper = this.$refs.wrapper;
        if (wrapper) {
          wrapper.scrollTop = 0;
        } else {
          console.error('Failed to find .wrapper element for scrolling.');
        }
      });
    },
    async completeRegistration() {
      this.nextLoading = true;
      const metaData = {
        name: this.isUserLoggedIn ? this.authUser.name : this.stepData[4].firstName + ' ' + this.stepData[4].lastName,
        email: this.isUserLoggedIn ? this.authUser.email : this.stepData[4].email,
        phone: this.isUserLoggedIn ? this.authUser.phone : this.stepData[4].phoneNumber,
        country: this.stepData[0].location.country,
        city: this.stepData[0].location.city,
        state: this.stepData[0].location.state,
        formatted_address: this.stepData[0].location.formatted_address,
        utc_offset_minutes: this.stepData[0].location.utc_offset_minutes,
        main_location: this.stepData[0].location.formatted_address,
        package_id: this.stepData[2].packages[0].id,
        from: this.stepData[3].time.from,
        duration: this.stepData[3].time.duration,
        utm_campaign: this.utm.utm_campaign || '',
        utm_medium: this.utm.utm_medium || '',
        utm_source: this.utm.utm_source || '',
        coupon_code: this.stripe.couponCode || ''
      };

      const response = await OrderApi.createStripeSession(metaData);
      if (response.statusCode === 200) {
        this.$store.dispatch('notification/addSuccessNotification', 'Stripe session has been created successfully');
        this.stripe.sessionId = response.data.sessionId;
        this.$refs.checkoutRef.redirectToCheckout();
        this.nextLoading = false;
      } else {
        this.nextLoading = false;
        this.$store.dispatch('notification/addFailureNotification', 'Failed to create a stipe session');
        return;
      }
    },
    async handleAbandonment(event) {
      // Prevent the default dialog (optional)

      if (this.currentStep === 7) {
        return;
      }
      event.preventDefault();
      event.returnValue = '';

      // Mapping of steps to status strings
      const stepStatuses = ['1. City', '2. Category', '3. Package', '4. Date and Time', '5. Contact', '6. OTP', '7. Order Confirmation', '8. Payment', '9. COMPLETE'];

      // Prepare the data to be sent
      const clientData = {
        firstName: this.stepData[4]?.firstName || '',
        lastName: this.stepData[4]?.lastName || '',
        email: this.stepData[4]?.email || '',
        phone: this.stepData[4]?.phoneNumber || '',
        city: this.stepData[0]?.location?.city || '',
        status: stepStatuses[this.currentStep - 1], // Map current step to status
        campaignSource: this.utm.utm_source || '',
        campaignMedium: this.utm.utm_medium || '',
        campaignName: this.utm.utm_campaign || ''
      };

      // Send a POST request to the /abandoned route
      await UserApi.abandon(clientData);
    },
    handleCitySelection(selectedCity) {
      this.stepData[0].location = selectedCity;
    },
    handleServiceSelection(selectedService) {
      this.stepData[1].service = selectedService;
    },
    handlePackagesSelection(selectedPackages) {
      this.stepData[2].packages = selectedPackages;
    },
    handleDatetimeSelection(selectedDatetime) {
      this.stepData[3].time = selectedDatetime.time;
    },
    handleUserDataChange(userData) {
      this.$set(this.stepData, 4, userData);
    },
    handleOtpChange(otp) {
      this.stepData[5].otp = otp;
    },
    handleCouponCode(code) {
      this.stripe.couponCode = code;
    },

    // Validation
    validateLocation(location) {
      // Define the schema for the location object
      const locationSchema = {
        city: { required, isString: value => !!value },
        country: { required, isString: value => !!value },
        formatted_address: { required, isString: value => !!value },
        state: { required, isString: value => !!value },
        utc_offset_minutes: { required, isNumber: value => typeof value === 'number' }
      };

      // Iterate over each property in the schema and check if it's valid
      for (const prop in locationSchema) {
        const validator = locationSchema[prop];
        const value = location[prop];

        // Check if the property is required and not empty
        if (validator.required && !value) {
          return false;
        }

        // Check if the property passes the custom validator (isString or isNumber)
        if (validator.isString && typeof value !== 'string') {
          return false;
        }

        if (validator.isNumber && typeof value !== 'number') {
          return false;
        }
      }

      // If all checks pass, the location is valid
      return true;
    },
    validateService(service) {
      // Define the schema for the service object
      const serviceSchema = {
        id: { required, isNumber: value => typeof value === 'number' },
        last_update: { required, isDate: value => !!value },
        name: { required, isString: value => !!value },
        num_pictures: { required, isNumber: value => typeof value === 'number' },
        purpose_id: { required, isNumber: value => typeof value === 'number' },
        status: { required, isString: value => !!value }
      };

      // Iterate over each property in the schema and check if it's valid
      for (const prop in serviceSchema) {
        const validator = serviceSchema[prop];
        const value = service[prop];

        // Check if the property is required and not empty
        if (validator.required && !value) {
          return false;
        }

        // Check if the property passes the custom validator (isDate, isNumber, or isString)
        if (validator.isDate && !isDate(value)) {
          return false;
        }

        if (validator.isNumber && !isNumber(value)) {
          return false;
        }

        if (validator.isString && !isString(value)) {
          return false;
        }
      }

      // If all checks pass, the service is valid
      return true;
    },
    validatePackage(packageObj) {
      // Define the schema for the package object
      const packageSchema = {
        creation_date: { required, isDate: value => !!value },
        currency: { required, isString: value => !!value },
        description: { required, isString: value => !!value },
        duration: { required, isNumber: value => typeof value === 'number' },
        id: { required, isNumber: value => typeof value === 'number' },
        is_plus: { isBoolean: value => typeof value === 'boolean' },
        last_update: { required, isDate: value => !!value },
        name: { required, isString: value => !!value },
        num_pictures: { required, isNumber: value => typeof value === 'number' },
        photographer_revenue: { required, isString: value => !!value },
        picture_number: { required, isNumber: value => typeof value === 'number' },
        price: { required, isString: value => !!value },
        price_per_additional_photo: { required, isString: value => !!value },
        service_id: { required, isNumber: value => typeof value === 'number' },
        status: { required, isString: value => !!value }
      };

      // Iterate over each property in the schema and check if it's valid
      for (const prop in packageSchema) {
        const validator = packageSchema[prop];
        const value = packageObj[prop];

        // Check if the property is required and not empty
        if (validator.required && !value) {
          return false;
        }

        // Check if the property passes the custom validator (isDate, isNumber, isString, or isBoolean)
        if (validator.isDate && !isDate(value)) {
          return false;
        }

        if (validator.isNumber && !isNumber(value)) {
          return false;
        }

        if (validator.isBoolean && !isBoolean(value)) {
          return false;
        }

        if (validator.isString && !isString(value)) {
          return false;
        }
      }

      // If all checks pass, the package is valid
      return true;
    },
    validateDate(selectedDate) {
      // Define the schema for the date string
      const dateSchema = {
        selectedDate: { required, isDate: value => !!value }
      };

      // Create an object with the date string to validate
      const dateObj = { selectedDate };

      // Iterate over each property in the schema and check if it's valid
      for (const prop in dateSchema) {
        const validator = dateSchema[prop];
        const value = dateObj[prop];

        // Check if the property is required and not empty
        if (validator.required && !value) {
          return false;
        }

        // Check if the property passes the isDate validator
        if (validator.isDate && !isDate(value)) {
          return false;
        }
      }

      // If all checks pass, the date is valid
      return true;
    },
    validateUserData(userData) {
      // Define the schema for the user data object
      const userDataSchema = {
        firstName: { required, isString: value => !!value },
        lastName: { required, isString: value => !!value },
        email: { required, emailValidator: value => !!value },
        phoneNumber: { required, phoneNumberValidator: value => !!value },
        phoneNumberApproval: { required, isTrue: value => value === true },
        emailExists: { customValidator: value => value === false }
      };

      // Iterate over each property in the schema and check if it's valid
      for (const prop in userDataSchema) {
        const validator = userDataSchema[prop];
        const value = userData[prop];

        // Check if the property is required and not empty
        if (validator.required && !value) {
          return false;
        }

        // Check if the property passes the custom validator (isString, isTrue, isFalse, or others)
        if (validator.isString && !isString(value)) {
          return false;
        }

        if (validator.isTrue && !isTrue(value)) {
          return false;
        }

        if (validator.isFalse && !isFalse(value)) {
          return false;
        }

        // Check if the property passes the custom emailValidator
        if (validator.emailValidator && !emailValidator(value)) {
          return false;
        }

        // Check if the property passes the custom phoneNumberValidator
        if (validator.phoneNumberValidator && !phoneNumberValidator(value)) {
          return false;
        }
        if (validator.customValidator && !validator.customValidator(value)) {
          return false;
        }
      }

      // If all checks pass, the user data is valid
      return true;
    },
    validateOtp(otp) {
      // Define the schema for the OTP
      const otpSchema = {
        otp: {
          required: true,
          isNumeric: value => /^\d+$/.test(value), // Check if it's numeric
          isLength6: value => value.length === 6 // Check if it's 6 characters in length
        }
      };

      // Create an object with the OTP to validate
      const otpObj = { otp };

      // Iterate over each property in the schema and check if it's valid
      for (const prop in otpSchema) {
        const validator = otpSchema[prop];
        const value = otpObj[prop];

        // Check if the property is required and not empty
        if (validator.required && (!value || value.trim() === '')) {
          return false;
        }

        // Check if the property passes the custom validators
        if (validator.isNumeric && !validator.isNumeric(value)) {
          return false;
        }

        if (validator.isLength6 && !validator.isLength6(value)) {
          return false;
        }
      }

      // If all checks pass, the OTP is valid
      return true;
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin: 0px;
  padding: 0px;
  position: relative;
  min-height: 100vh;
  height: auto !important;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center;
  background-size: cover;
}

.stepper-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0.7;
  padding: 0;
  margin: 0;
}

.header {
  z-index: 10;
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 30px;
}

.login {
  margin-left: auto;
  text-decoration: none;
  color: var(--main-text-color);
  font-size: 18px;
}

.slogon {
  position: absolute;
  top: -95px;
  @include antonio-font($size: 20px, $uppercase: true, $color: black);
  & > span {
    color: var(--main-btn-color);
  }
}
.contact {
  width: 850px;
  position: absolute;
  bottom: -70px;
  text-align: right;
  padding: 0;
  margin: 0;
  @include antonio-font($size: 20px, $uppercase: true, $color: black);
  & > a {
    color: var(--main-btn-color);
    text-decoration: none;
  }
}

.stepper-wrapper {
  position: relative;
  min-height: 100%;
}

.stepper {
  width: 850px !important;
  height: 600px !important;
  position: relative;
  border-radius: 15px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.navigation {
  bottom: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: transparent !important;

  & > a {
    display: none;
  }
}

@include responsive($max: mobileL) {
  .stepper {
    width: 100vw !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    background-color: transparent !important;
    border-radius: 0px !important;
    border: 0 !important;
    box-shadow: none !important;
  }
  .navigation {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0; /* Align to the bottom */
    background-color: white !important;
    padding: 10px;
    padding-bottom: calc(10px + env(safe-area-inset-bottom)); /* Add safe area inset to the bottom padding */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for better separation from content */
    z-index: 100; /* Ensure it's above other content */

    & > a {
      display: block;
      text-decoration: none;
      @include antonio-font($size: 18px, $uppercase: true, $color: black);
      position: absolute !important;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 10px;
      & > span {
        color: var(--main-btn-color);
      }
    }
  }

  .wrapper {
    display: block;
    margin: 0px;
    padding: 0px;
    position: relative;
    min-height: 100% !important;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
    padding-bottom: 200px !important;
  }
  .stepper-wrapper {
    position: relative;
    width: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
  }

  .header {
    display: flex;
    position: relative;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  .logo {
    align-self: center;
    z-index: 10;
    margin-top: 24px;
  }

  .login {
    display: none;
  }

  .slogon {
    display: none;
  }
  .contact {
    display: none;
  }
}
</style>
