<template>
  <carousel-3d
    ref="carousel"
    :style="{ borderRadius: '10px' }"
    :count="items.length"
    :display="display"
    :perspective="perspective"
    :space="space"
    :width="width"
    :border="0"
    :height="height"
    @after-slide-change="onAfterSlideChange"
  >
    <slide :key="0" :index="0" :style="{ borderRadius: '15px' }">
      <template>
        <PackageCardMobile :pkg="items[0]" :key="items[0].id" :img="getPackageImage({ idPackage: items[0].id, idAsset: 1 })" />
      </template>
    </slide>
    <slide v-for="(item, index) in filteredItems" :index="index + 1" :key="index + 1" :style="{ borderRadius: '15px' }">
      <template>
        <PackageCardMobile :pkg="item" :key="item.id" :img="getPackageImage({ idPackage: item.id, idAsset: index + 1 })" @click="emitPackageSelected(item)" />
      </template>
    </slide>
  </carousel-3d>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
import PackageCardMobile from '@/components/package/PackageCardMobile.vue';

export default {
  name: 'PkgCarousel',
  components: {
    Carousel3d,
    PackageCardMobile,
    Slide
  },
  props: {
    display: { default: 3, type: Number },
    items: { default: () => [], type: Array },
    perspective: { default: 0, type: Number },
    space: { default: 100, type: Number },
    width: { type: Number },
    height: { type: Number }
  },
  data() {
    return {
      startX: 0,
      startY: 0
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item, index) => index !== 0);
    }
  },
  methods: {
    onAfterSlideChange(index) {
      this.$emit('package-selected', index);
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].pageX;
      this.startY = event.touches[0].pageY;
    },
    handleTouchMove(event) {
      const moveX = event.touches[0].pageX - this.startX;
      const moveY = event.touches[0].pageY - this.startY;

      if (Math.abs(moveX) > Math.abs(moveY)) {
        event.preventDefault();
      }
    },
    preventTouchScroll(event) {
      event.preventDefault();
    }
  },
  mounted() {
    this.$emit('package-selected', 0);
    const carouselElement = this.$refs.carousel.$el;
    carouselElement.addEventListener('touchstart', this.handleTouchStart, { passive: true });
    carouselElement.addEventListener('touchmove', this.handleTouchMove, { passive: false });
  },
  beforeDestroy() {
    const carouselElement = this.$refs.carousel.$el;
    carouselElement.removeEventListener('touchstart', this.handleTouchStart);
    carouselElement.removeEventListener('touchmove', this.handleTouchMove);
  }
};
</script>
