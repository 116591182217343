<template>
  <div class="container">
    <h2 class="step-header">Select your preferred date and time</h2>
    <div class="grid-container">
      <BaseCalendar :showButton="false" :defaultDate="selectedDate" ref="calendar" flat :options="filterPastDate" @new-date="handleDateChange" class="calendar" />

      <div class="time-selection">
        <div class="row">
          <p>{{ $t(`The shoot duration is ${this.package.duration} hours`) }}</p>
        </div>
        <div class="row">
          <p>{{ $t('FROM') }}</p>
          <BaseSelect v-model="selectedTime" :clearable="false" :options="timeOptions" @update:value="handleTimeChange" :style="{ width: '150px' }" />
        </div>
        <div class="row">
          <p>{{ $t('TO') }}</p>
          <p class="to">{{ computedToTime }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCalendar from '@base/BaseCalendar.vue';
import BaseSelect from '@base/BaseSelect.vue';
import moment from 'moment';

export default {
  name: 'Step3',
  props: {
    user: Object,
    package: Object
  },
  components: {
    BaseCalendar,
    BaseSelect
  },
  data() {
    return {
      selectedDate: '',
      selectedTime: '08:00', // Default to 08:00 in 24-hour format
      timeOptions: [...Array(24)].map((_, i) => `${String(i).padStart(2, '0')}:00`)
    };
  },

  computed: {
    computedToTime() {
      if (this.selectedTime) {
        const [hours, minutes] = this.selectedTime.split(':');
        const toMoment = moment({ hour: parseInt(hours, 10), minute: parseInt(minutes, 10) }).add(this.package.duration, 'hours');
        return toMoment.format('HH:mm');
      }
      return '';
    }
  },
  methods: {
    handleDateChange(newDate) {
      this.selectedDate = newDate;
      this.combineDateAndTime();
    },
    handleTimeChange(newTime) {
      this.selectedTime = newTime;
      this.combineDateAndTime();
    },

    combineDateAndTime() {
      if (this.selectedDate && this.selectedTime) {
        // Extract the date part from the selectedDate
        const datePart = this.selectedDate.split('T')[0];

        // Combine date and time into an ISO 8601 formatted string
        const combinedDateTime = `${datePart}T${this.selectedTime}:00Z`;

        // Emit the combined datetime
        this.$emit('datetime-selected', {
          time: {
            duration: this.package.duration * 60,
            from: combinedDateTime
          }
        });
      }
    },

    filterPastDate(date) {
      const currentDateTime = moment();
      const futureDateTime = currentDateTime.clone().add(24, 'hours');
      return moment(date) > futureDateTime;
    }
  }
};
</script>

<style scoped lang="scss">
.step-header {
  font-size: 18px;
  font-weight: normal;
  color: #000;
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 6px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.time-selection {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.to {
  width: 150px !important;
  text-align: left;
}
.calendar {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

@include responsive($max: mobileL) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .calendar {
    width: 100% !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
  }
  .time-selection {
    background-color: rgba(255, 255, 255, 0.7) !important;
  }
}
</style>
