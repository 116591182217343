<template>
  <div class="baseCard" :style="custom_style">
    <slot name="header" />
    <slot name="body" />
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    /**
     * Define card custom style
     */
    custom_style: { default: () => ({}), type: Object }
  }
};
</script>

<style lang="scss" scoped>
.baseCard {
  background-color: var(--card-bg-color);
  box-shadow: 0 2px 15px var(--card-shadow-color);
  border-radius: 10px;
  height: 279px;
  overflow: hidden;
  position: relative;
}
</style>
